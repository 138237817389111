import React from 'react'
import LayoutTwo from '../components/layoutTwo'
import { PageLinks } from '../common/site/page-static-links'
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import OffPlanDesc from '../components/OffPlanDesc/OffPlanDesc';
import sitelogoimage from "../images/logo.png";
import Seo from '../components/seo';
import { useTranslation } from "react-i18next"
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const OffplanDetailBanner = loadable(() => import("../components/NewHomesDetailsBanner/OffplanDetailBanner"));
const OffplanPropertyNavSticky = loadable(() => import("../components/PropertyNavSticky/OffplanPropertyNavSticky"));
const OffPlanVideo = loadable(() => import("../components/OffPlanVideo/OffPlanVideo"));
const OffPlanPayment = loadable(() => import("../components/OffPlanPayment/OffPlanPayment"));
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
const OffplanDetailsProperties = loadable(() => import("../components/NewHomesDetailsMoreProperties/OffplanDetailsProperties"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModuleGlobal"));
const NewHomesDetailsAvailability = loadable(() => import("../components/NewHomesDetailsAvailability/NewHomesDetailsAvailability"));
const Enquire = loadable(() => import("../components/Enquire/Enquire"));
const { Site_Vars } = require("../common/site/config");
const OffPlanDetails = ({ data, children }) => {
  const { t } = useTranslation()
  const offplanData = data?.strapiOffPlan
  const availpropertyData = data?.allStrapiProperty?.edges
  // if (!offplanData) {
  //   return null
  // }
  const url = typeof window !== 'undefined' ? window.location.href : ''
  const imagename = "off-plan.images.offplan_details";

  let breadcrumData;

  if (PageLinks?.offplan_parent_label) {
    breadcrumData = { parentname: t("offplan_parent_label"), parent: PageLinks.offplan_parent, subparentname: t("offplan_label"), subparent: PageLinks.offplan, pagename: offplanData?.display_address }
  } else {
    breadcrumData = { parentname: t("offplan_label"), parent: PageLinks.offplan, pagename: offplanData?.display_address }
  }

  let propertyDetailImg = [];
  for (let i = 0; i < offplanData?.images?.strapi_json_value?.length; i++) {
    if (offplanData?.images?.strapi_json_value?.[i].url) {
      propertyDetailImg.push(offplanData?.images?.strapi_json_value[i].url);
    }
  }

  let processedImages = JSON.stringify({});
  if (offplanData?.imagetransforms?.images_Transforms) {
    processedImages = offplanData?.imagetransforms?.images_Transforms;
  }

  return (
    <LayoutTwo tag={"offplan-details-template"}>
      <div className="layout-padding-top">
        <BreadcrumbModule {...breadcrumData} />

        <OffplanDetailBanner
          {...offplanData}
          propImg={propertyDetailImg}
          processedImages={processedImages}
          strapi_id={offplanData?.strapi_id}
          imagename={imagename}
          crm_id={offplanData?.crm_id}
          prop_id={offplanData?.strapi_id}
          offplan
        />

        <OffplanPropertyNavSticky  {...offplanData} propImg={propertyDetailImg} processedImages={processedImages} imagename={imagename} avail={availpropertyData} />

        <OffPlanDesc {...offplanData} pageurl={url} propImg={propertyDetailImg} />

        <OffPlanVideo videoData={offplanData?.video_module} />

        <OffPlanPayment paymentplanData={offplanData?.payment_plans} />

        {availpropertyData && availpropertyData.length > 0 && <NewHomesDetailsAvailability data={availpropertyData} /> }

        {(offplanData?.latitude && offplanData?.longitude) &&
          <PropertyDetailsMap
            lat={offplanData?.latitude}
            lng={offplanData?.longitude}
          />
        }
        <OffplanDetailsProperties prop_id={offplanData?.strapi_id} />

        <ValuationModule />
      </div>
      <div className="d-md-none">
        <Enquire tag="property-details" strapi_id={offplanData?.strapi_id} crm_id={offplanData?.crm_id} display_address={offplanData?.display_address} pageurl={url} propImg={propertyDetailImg} />
      </div>
    </LayoutTwo>
  )
}

export const query = graphql`
  query ($locale: String!,$strapi_id: Int, $master_id: String) {
    strapiOffPlan(strapi_id: {eq: $strapi_id}, locale: { eq: $locale }) {
        ...OffplanFragment
  }
  allStrapiProperty(filter: {publish: {eq: true}, locale: { eq: $locale }, master_id: {eq: $master_id}}) {
    edges {
        node {
          id
          slug
          locale
          strapi_id
          images {
            strapi_json_value {
              url
            }
          }
          crm_id
          price_qualifier
          price
          search_type
          title
          display_address
          building {
            strapi_json_value
          }
          bathroom
          bedroom
          floorarea_max
          status
          imagetransforms {
            images_Transforms
          }
          ggfx_results {
              src_cftle
              transforms {
                  url
                  transform
              }
          }
        }
    }
}
}
`

export const Head = (props) => {
  const { t } = useTranslation()
  //seo title, h1 and desc prepare, this can send to utilis function later
  let desc_meta_ptype = props.data.strapiOffPlan?.building?.strapi_json_value?.length > 0 ? props.data.strapiOffPlan?.building?.strapi_json_value.join(" and ") : "off plan"


  let cap_ptype = desc_meta_ptype?.charAt(0).toUpperCase() + desc_meta_ptype?.slice(1);

  let desc_meta_seachtype = t("for_sale")
  // if (props.data.strapiOffPlan?.search_type == "lettings") {
  //     desc_meta_seachtype = `for rent`
  // }
  let desc_meta_beds = props.data.strapiOffPlan?.display_bedrooms
  let desc_meta_address = props.data.strapiOffPlan?.display_address
  let desc_meta_price = Site_Vars.default_currency + new Intl.NumberFormat('en-UK').format(parseInt(props.data.strapiOffPlan?.price?.replace(/,/g, ''), 10))

  let pagetitle = `${cap_ptype} ${desc_meta_seachtype} ${t("with")} ${desc_meta_beds} ${t("bedrooms")} ${t("in")} ${desc_meta_address} ${t("at")} ${desc_meta_price}`

  let pagemetadesc = `${t("know_details")} ${desc_meta_ptype} ${desc_meta_seachtype} ${t("with")} ${desc_meta_beds} ${t("bedrooms")} ${t("in")} ${desc_meta_address} ${t("at")} ${desc_meta_price}. ${t("book_a_viewing")} ${process.env.GATSBY_SITE_NAME} ${t("get_assistance", {desc_meta_ptype: desc_meta_ptype})}`

  let pageUrl = process.env.GATSBY_SITE_URL + (props?.location?.pathname).replace(/\/?$/, '/')
  let myimgtransforms = ''
  if (props.data.strapiOffPlan?.imagetransforms?.images_Transforms) {
    myimgtransforms = JSON.parse(props.data.strapiOffPlan?.imagetransforms?.images_Transforms);
  }
  let pageImg = sitelogoimage;
  if (myimgtransforms && Object.keys(myimgtransforms).length > 0) {
    let mymetaimg = Object.values(myimgtransforms);
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform]['webp'] !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform]['webp']);
        break;
      }
    }
    if (pageImg_filt.length > 0)
      pageImg = pageImg_filt[0]
  }


  var ldJson = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": props.data.strapiOffPlan?.slug?.replace(/-ar/g, "").replace(/-/g, " "),
    "image": props.data.strapiOffPlan?.images?.length > 0 ? props.data.strapiOffPlan?.images[0]?.url : sitelogoimage,
    "description": pagemetadesc,
    "brand": {
      "@type": "Organization",
      "name": process.env.GATSBY_SITE_NAME,
      "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`
    },
    "offers": {
      "@type": "Offer",
      "url": pageUrl,
      "priceCurrency": "GBP",
      "price": props.data.strapiOffPlan?.price,
      "availability": "https://schema.org/InStock"
    }

  };
  return (
    <Seo title={pagetitle} description={pagemetadesc}>
      <meta name="image" content={pageImg} />
      <meta name="og:image" content={pageImg} />
      <meta name="og:image:width" content="400" />
      <meta name="og:image:height" content="300" />
      <meta name="twitter:image" content={pageImg} />
      <meta name="og:url" content={pageUrl} />
      <meta name="twitter:url" content={pageUrl} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />
    </Seo>
  )
}
export default OffPlanDetails